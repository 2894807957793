import { useState } from "react"
import constate from "constate"

import { AccountId } from "query/graphql"

type Values = {
  governanceId: AccountId
  setGovernanceId: React.Dispatch<React.SetStateAction<AccountId>>
}

type Props = {
  governanceId: AccountId
}

const useSelectedGovernanceIdConstate = ({
  governanceId: governanceIdProp,
}: Props): Values => {
  const [governanceId, setGovernanceId] =
    useState<AccountId>(governanceIdProp)

  return {
    governanceId,
    setGovernanceId,
  }
}

const [SelectedGovernanceIdProvider, useSelectedGovernanceId] = constate(
  useSelectedGovernanceIdConstate,
)

export { SelectedGovernanceIdProvider, useSelectedGovernanceId }

import type { FC } from "react"
import React from "react"
import flatten from "lodash.flatten"
import type { StackProps } from "@chakra-ui/react"
import {
  Flex,
  Text,
  Stack,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  HStack,
  Center,
} from "@chakra-ui/react"
import { getAddress } from "@ethersproject/address"

import type {
  Account,
  AddressDaosProposalsQuery,
  AddressDaosProposalsQueryVariables,
  Governor,
  Organization,
  Proposal,
} from "query/graphql"
import { AddressDaosProposalsDocument, ProposalsSortBy } from "query/graphql"
import VoteSupport from "common/components/columns/VoteSupport"
import {
  ProposalIdentity,
  ProposalIdentityMobile,
} from "common/components/columns/ProposalIdentity"
import ProposalVotesFor from "common/components/columns/ProposalVotesFor"
import ProposalVotesAgainst from "common/components/columns/ProposalVotesAgainst"
import { generateArrayOfNumbers } from "common/helpers/array"
import { useDevice } from "common/hooks/useDevice"
import CardContainer from "common/components/CardContainer"
import LoadMoreButton from "common/components/LoadMoreButton"
import { useInfiniteCursorQuery } from "query/hooks/useInfiniteQuery"

type Props = {
  decimals?: number
  governors: Governor[]
  isWhiteLabel?: boolean
  isTabActive: boolean
  voterAddress: string
  slug: string
  organization: Organization
}

const AddressDAOSpecificVotes: FC<Props & StackProps> = ({
  decimals,
  governors,
  isWhiteLabel,
  isTabActive,
  voterAddress,
  slug,
  organization,
  ...stackProps
}) => {
  return (
    <CardContainer isTableView>
      <Stack as="section" {...stackProps}>
        <Stack borderRadius={1}>
          <AddressDAOSProposals
            decimals={decimals}
            governors={governors}
            isTabActive={isTabActive}
            isWhiteLabel={isWhiteLabel}
            organization={organization}
            slug={slug}
            voterAddress={voterAddress}
          />
        </Stack>
      </Stack>
    </CardContainer>
  )
}

const PAGE_SIZE = 5

type AddressDAOSProposalsProps = {
  dateOfFirstParticipation?: string
  decimals?: number
  governors: Governor[]
  isWhiteLabel?: boolean
  isTabActive: boolean
  voterAddress: string
  slug: string
  organization: Organization
}
const AddressDAOSProposals: FC<AddressDAOSProposalsProps> = ({
  governors,
  voterAddress,
  isWhiteLabel,
  isTabActive,
  decimals,
  slug,
  organization,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()

  const [governor] = governors

  const { data, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteCursorQuery<
      AddressDaosProposalsQuery,
      AddressDaosProposalsQueryVariables
    >({
      document: AddressDaosProposalsDocument,
      sectionName: "AddressDaosProposals",
      nextPagePath: "proposals",
      variables: {
        input: {
          filters: {
            organizationId: organization.id,
          },
          sort: {
            sortBy: ProposalsSortBy.Id,
            isDescending: true,
          },
          page: { limit: PAGE_SIZE },
        },
        address: voterAddress,
      },
      enabled: isTabActive && Boolean(governor?.id),
    })

  const isLoadMoreDisabled = !hasNextPage

  const handleLoadMoreClick = () => fetchNextPage()

  if (isLoading) {
    return (
      <Stack mt={2} mx={2}>
        <Flex borderBottom="gray.light" h="2rem" w="full">
          <Skeleton h="full" w="full" />
        </Flex>
        {generateArrayOfNumbers(4).map((number) => (
          <Flex
            key={`recent_vote_${number}`}
            borderBottom="gray.light"
            h="3.606875rem"
            w="full"
          >
            <Skeleton h="full" w="full" />
          </Flex>
        ))}
      </Stack>
    )
  }

  if (data === undefined) return null

  const { pages } = data

  const allParticipations = pages.map(
    ({ proposals }) => proposals.nodes as Proposal[],
  )

  const allProposals = flatten(allParticipations.map((thing) => thing))

  const didProposeVote = (proposer: Account) => {
    if (getAddress(proposer.address) === getAddress(voterAddress))
      return (
        <Badge colorScheme="purple" variant="outline">
          Proposer
        </Badge>
      )

    return null
  }

  const hasProposals = allProposals && allProposals.length

  return (
    <>
      {allProposals?.length > 0 ? (
        <>
          <TableContainer display={onLargeDevice}>
            <Flex
              align="stretch"
              direction="column"
              display={onLargeDevice}
              w="full"
            >
              <Table>
                <Thead>
                  <Tr>
                    <Th>Proposal</Th>
                    <Th>Votes for</Th>
                    <Th>Votes against</Th>
                    <Th isNumeric>Voted?</Th>
                  </Tr>
                </Thead>
                {allProposals.map((proposal) => (
                  <Tr key={`proposal-${proposal.id}`}>
                    <Td>
                      {typeof decimals !== "undefined" &&
                      decimals >= 0 &&
                      governor ? (
                        <ProposalIdentity
                          decimals={decimals}
                          governor={governor}
                          isWhiteLabel={isWhiteLabel}
                          organization={organization}
                          proposal={proposal}
                          slug={slug}
                        />
                      ) : null}
                    </Td>
                    <Td>
                      {typeof decimals !== "undefined" && decimals >= 0 ? (
                        <ProposalVotesFor
                          decimals={decimals}
                          voteStats={proposal.voteStats}
                        />
                      ) : null}
                    </Td>
                    <Td>
                      {typeof decimals !== "undefined" && decimals >= 0 ? (
                        <ProposalVotesAgainst
                          decimals={decimals}
                          voteStats={proposal.voteStats}
                        />
                      ) : null}
                    </Td>
                    <Td>
                      <HStack justifyContent="flex-end">
                        {proposal?.creator
                          ? didProposeVote(proposal.creator)
                          : null}
                        <VoteSupport
                          participationType={proposal.participationType}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
                <Tbody />
              </Table>
            </Flex>
          </TableContainer>

          <TableContainer display={onLittleDevice}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Proposal</Th>
                  <Th isNumeric>Ballot</Th>
                </Tr>
              </Thead>
              {allProposals.map((proposal) => (
                <Tr key={`proposal-mobile-${proposal.id}`}>
                  <Td maxW="2em">
                    {typeof decimals !== "undefined" &&
                    decimals >= 0 &&
                    governor ? (
                      <ProposalIdentityMobile
                        decimals={decimals}
                        governor={governor}
                        isWhiteLabel={isWhiteLabel}
                        organization={organization}
                        proposal={proposal}
                        slug={slug}
                      />
                    ) : null}
                  </Td>
                  <Td isNumeric>
                    <VoteSupport
                      participationType={proposal.participationType}
                    />
                  </Td>
                </Tr>
              ))}
              <Tbody />
            </Table>
          </TableContainer>

          {hasProposals ? (
            <Center>
              <LoadMoreButton
                isDisabled={isLoadMoreDisabled}
                onClick={handleLoadMoreClick}
              />
            </Center>
          ) : null}
        </>
      ) : (
        <Flex align="center" bg="gray.50" h={16} justify="center" width="100%">
          <Text color="gray.600" textStyle="body.bold.md">
            No proposals created yet
          </Text>
        </Flex>
      )}
    </>
  )
}

export default AddressDAOSpecificVotes

import { Stack } from "@chakra-ui/react"
import { useState, type FC } from "react"

import type { Organization } from "query/graphql"
import { DelegatingToTag } from "address/components/AddressHeader"
import CardContainer from "common/components/CardContainer"

type Props = {
  organization: Organization
  voterAddress: string
}
const AddressDelegatePanels: FC<Props> = ({ organization, voterAddress }) => {
  const tokenIds = organization?.tokenIds ?? []

  const [displayDelegateToPanel, setDisplayDelegateToPanel] = useState(false)

  return (
    <CardContainer
      display={{ base: displayDelegateToPanel ? "block" : "none" }}
      title="Delegating to"
    >
      <Stack pt={{ base: 4, md: 0 }} px={0} spacing={2} width="auto">
        {tokenIds.map((tokenId, idx) => (
          <DelegatingToTag
            key={`mobile-delegating-to-${idx}`}
            isMultiChain
            slug={organization.slug}
            tokenId={tokenId}
            voterAddress={voterAddress}
            onDelegateeFound={() => setDisplayDelegateToPanel(true)}
          />
        ))}
      </Stack>
    </CardContainer>
  )
}

export default AddressDelegatePanels

import type { FC } from "react"
import React from "react"
import capitalize from "lodash.capitalize"
import type { FlexProps } from "@chakra-ui/react"
import { Flex, Stack, Text, Icon } from "@chakra-ui/react"

import { useDevice } from "common/hooks/useDevice"
import type { VoteStyle } from "common/helpers/voteStyle"
import { ProposalParticipationType } from "query/graphql"

type Props = {
  type: string
  shouldShowMobileLabel?: boolean
  voteStyle: VoteStyle
}

const VoteLegend: FC<Props & FlexProps> = ({
  type,
  voteStyle,
  shouldShowMobileLabel = false,
  ...flexProps
}) => {
  const { color, icon, colorScheme } = voteStyle
  const typeLabel = capitalize(type)

  const { onLargeDevice, onAllDevices } = useDevice()

  const display = shouldShowMobileLabel ? onAllDevices : onLargeDevice

  return (
    <Flex
      bg={`${colorScheme}.50`}
      direction="column"
      justify="center"
      p={2}
      rounded="full"
      {...flexProps}
    >
      <Stack isInline align="center" px={2} spacing={2}>
        <Icon as={icon} color={color} h={4} w={4} />
        <Text color={color} display={display} textStyle="body.bold.md">
          {typeLabel}
        </Text>
      </Stack>
    </Flex>
  )
}

type ParticipationLegendProps = {
  participationType: ProposalParticipationType
  shouldShowMobileLabel?: boolean
  voteStyle: VoteStyle
}

export const ParticipationLegend: FC<ParticipationLegendProps & FlexProps> = ({
  participationType,
  voteStyle,
  shouldShowMobileLabel = false,
  ...flexProps
}) => {
  const { color, icon, colorScheme } = voteStyle
  const supportLabel: { [key in ProposalParticipationType]: string } = {
    [ProposalParticipationType.Votedabstain]: "Abstain",
    [ProposalParticipationType.Votedfor]: "For",
    [ProposalParticipationType.Votedagainst]: "Against",
    [ProposalParticipationType.Notdelegate]: "Not a member",
    [ProposalParticipationType.Notvoted]: "No vote",
    [ProposalParticipationType.Unknown]: "Unknown",
  }

  const { onLargeDevice, onAllDevices } = useDevice()

  const display = shouldShowMobileLabel ? onAllDevices : onLargeDevice

  return (
    <Flex
      bg={`${colorScheme}.50`}
      direction="column"
      justify="center"
      p={2}
      rounded="full"
      {...flexProps}
    >
      <Stack isInline align="center" px={2} spacing={2}>
        <Icon as={icon} color={color} h={4} w={4} />
        <Text color={color} display={display} textStyle="body.bold.md">
          {supportLabel[participationType]}
        </Text>
      </Stack>
    </Flex>
  )
}

export default VoteLegend

import type { FC } from "react"
import React from "react"
import type { TooltipProps } from "@chakra-ui/react"
import { Tooltip } from "@chakra-ui/react"

const AddressTooltip: FC<TooltipProps> = (
  { children, label, ...tooltipProps },
  ref,
) => {
  const hasLabel = Boolean(label)

  return (
    <Tooltip
      ref={ref}
      hasArrow
      display="flex"
      isDisabled={!hasLabel}
      justifyContent="center"
      label={label}
      minW="27em"
      placement="top"
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  )
}

export default AddressTooltip

import type { FC } from "react"
import React from "react"
import flatten from "lodash.flatten"
import type { StackProps } from "@chakra-ui/react"
import { Flex, Stack, Center, Skeleton, Wrap, WrapItem } from "@chakra-ui/react"

import AddressCreatedProposalsTable from "address/components/AddressCreatedProposalsTable"
import type {
  CreatedProposalsQuery,
  CreatedProposalsQueryVariables,
  Governor,
  Organization,
  Proposal,
  Token,
} from "query/graphql"
import {
  CreatedProposalsDocument,
  ProposalStatus,
  ProposalsSortBy,
} from "query/graphql"
import { useInfiniteCursorQuery } from "query/hooks/useInfiniteQuery"
import LoadMoreButton from "common/components/LoadMoreButton"
import { generateArrayOfNumbers } from "common/helpers/array"
import CardContainer from "common/components/CardContainer"
import StatBox from "common/components/StatBox"

type Props = {
  organization: Organization
  governors: Governor[]
  isWhiteLabel?: boolean
  isTabActive: boolean
  voterAddress: string
  slug: string
  token: Pick<Token, "decimals">
  proposalsCount: number
}

const AddressCreatedProposals: FC<Props & StackProps> = ({
  organization,
  governors,
  isWhiteLabel = false,
  isTabActive,
  voterAddress,
  slug,
  token,
  proposalsCount,
  ...stackProps
}) => {
  const PAGE_SIZE = 5

  const {
    data: createdProposals,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteCursorQuery<
    CreatedProposalsQuery,
    CreatedProposalsQueryVariables
  >({
    document: CreatedProposalsDocument,
    sectionName: "CreatedProposals",
    nextPagePath: "proposals",
    variables: {
      input: {
        filters: {
          organizationId: organization.id,
          proposer: voterAddress,
        },
        sort: {
          sortBy: ProposalsSortBy.Id,
          isDescending: true,
        },
        page: { limit: PAGE_SIZE },
      },
    },
    enabled: isTabActive && Boolean(organization.id) && Boolean(voterAddress),
  })

  const handleLoadMoreClick = () => fetchNextPage()

  const isLoadMoreDisabled = !hasNextPage

  if (isLoading) {
    return (
      <Stack {...stackProps}>
        <Flex align="center" h={10} justify="space-between">
          <Skeleton h="full" w={32} />
        </Flex>
        <Flex border="gray.light" direction="column" rounded="sm" w="full">
          <Flex borderBottom="gray.light" h="2.856875rem" w="full">
            <Skeleton h="full" w="full" />
          </Flex>
          {generateArrayOfNumbers(4).map((number) => (
            <Flex
              key={`created_proposal_${number}`}
              borderBottom="gray.light"
              h="4.606875rem"
              w="full"
            >
              <Skeleton h="full" w="full" />
            </Flex>
          ))}
          <Flex h={12} w="full">
            <Skeleton h="full" w="full" />
          </Flex>
        </Flex>
      </Stack>
    )
  }

  if (!createdProposals) return null

  const { pages } = createdProposals

  const allProposals = flatten(
    pages.map(({ proposals }) => proposals.nodes as Proposal[]),
  )

  const proposalStatuses = allProposals.map(({ status }) => status)

  const succeededStatuses = proposalStatuses.filter(
    (status) => status === ProposalStatus.Succeeded,
  )

  const cancelledStatuses = proposalStatuses.filter(
    (status) => status === ProposalStatus.Canceled,
  )

  // Calculate the percentage of "SUCCEEDED" statuses
  const succeededPercentage =
    (succeededStatuses.length /
      (proposalStatuses.length - cancelledStatuses.length)) *
    100

  const percentageOrZero = isNaN(succeededPercentage)
    ? 0
    : succeededPercentage.toFixed(2)

  const hasProposals = allProposals && allProposals.length > 0

  return (
    <CardContainer isTableView>
      <Stack as="section" {...stackProps}>
        <>
          <Stack mb={6}>
            <Wrap pl={6} pt={6} spacing={8}>
              <WrapItem>
                <StatBox
                  dataQa="Proposals-created-2"
                  label="Proposals Created"
                  size="2xl"
                  value={proposalsCount}
                />
              </WrapItem>
              <WrapItem>
                <StatBox
                  label="Success Rate"
                  size="2xl"
                  value={`${percentageOrZero}%`}
                />
              </WrapItem>
            </Wrap>
          </Stack>
        </>
        {organization?.id ? (
          <AddressCreatedProposalsTable
            governors={governors}
            isWhiteLabel={isWhiteLabel}
            organization={organization}
            proposals={allProposals}
            slug={slug}
            token={token}
          />
        ) : null}
        {hasProposals ? (
          <Center>
            <LoadMoreButton
              isDisabled={isLoadMoreDisabled}
              onClick={handleLoadMoreClick}
            />
          </Center>
        ) : null}
      </Stack>
    </CardContainer>
  )
}

export default AddressCreatedProposals

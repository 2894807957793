import type { FC } from "react"
import React from "react"

import { useDevice } from "common/hooks/useDevice"
import type { Column } from "ui/components/Table"
import Table from "ui/components/Table"
import { useSelectedGovernanceId } from "address/hooks/useSelectedGovernanceId"
import type { Governor, Organization, Proposal, Token } from "query/graphql"
import {
  ProposalIdentity,
  ProposalIdentityMobile,
} from "common/components/columns/ProposalIdentity"
import ProposalVotesFor from "common/components/columns/ProposalVotesFor"
import ProposalVotesAgainst from "common/components/columns/ProposalVotesAgainst"
import ProposalVotesTotal from "common/components/columns/ProposalVotesTotal"

type Props = {
  proposals: Proposal[]
  organization: Organization
  governors: Governor[]
  slug: string
  isWhiteLabel?: boolean
  token: Pick<Token, "decimals">
}

const AddressCreatedProposalsTable: FC<Props> = ({
  proposals,
  organization,
  governors,
  slug,
  isWhiteLabel,
  token,
}) => {
  const { onLargeDevice, onLittleDevice } = useDevice()
  const { governanceId } = useSelectedGovernanceId()

  const [governor] = governors

  const generateLargeColumns = (proposals: Proposal[]): Column[] => [
    {
      title: "Proposal",
      isNumeric: false,
      cells: proposals.map((proposal) => (
        <ProposalIdentity
          key={`address-created-proposals-identity-${proposal.id}-${governanceId}`}
          decimals={token.decimals}
          governor={governor}
          isWhiteLabel={isWhiteLabel}
          organization={organization}
          proposal={proposal}
          slug={slug}
        />
      )),
    },
    {
      title: "Votes for",
      isNumeric: false,
      cells: proposals.map((proposal) => {
        return (
          <ProposalVotesFor
            key={`address-created-proposals-votes-for-${proposal.id}-${governanceId}`}
            decimals={token.decimals}
            voteStats={proposal.voteStats}
          />
        )
      }),
    },
    {
      title: "Votes against",
      isNumeric: false,
      cells: proposals.map((proposal) => {
        return (
          <ProposalVotesAgainst
            key={`address-created-proposals-votes-against-${proposal.id}-${governanceId}`}
            decimals={token.decimals}
            voteStats={proposal.voteStats}
          />
        )
      }),
    },
    {
      title: "Total votes",
      isNumeric: true,
      cells: proposals.map((proposal) => {
        return (
          <ProposalVotesTotal
            key={`address-created-proposals-totals-${proposal.id}-${governanceId}`}
            decimals={token.decimals}
            proposal={proposal}
          />
        )
      }),
    },
  ]

  const generateLittleColumns = (proposals: Proposal[]): Column[] => [
    {
      title: "Proposal",
      isNumeric: false,
      cells: proposals.map((proposal) => (
        <ProposalIdentityMobile
          key={`address-created-proposals-identity-${proposal.id}-${governanceId}`}
          decimals={token.decimals}
          governor={governor}
          organization={organization}
          proposal={proposal}
          slug={slug}
        />
      )),
    },
  ]

  const largeColumns: Column[] = generateLargeColumns(proposals)
  const littleColumns: Column[] = generateLittleColumns(proposals)

  return (
    <>
      <Table
        columns={largeColumns}
        display={onLargeDevice}
        emptyMessage="No proposals found"
        id="address-created-proposals"
      />
      <Table
        columns={littleColumns}
        display={onLittleDevice}
        emptyMessage="No proposals found"
        id="address-created-proposals"
      />
    </>
  )
}

export default AddressCreatedProposalsTable

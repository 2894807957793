import React from "react"
import { Flex } from "@chakra-ui/react"

import type { ProposalParticipationType } from "query/graphql"
import { ParticipationLegend } from "voting/components/VoteLegend"
import { getParticipationTypeStyles } from "common/helpers/voteStyle"

type Props = {
  participationType: ProposalParticipationType
}

const VoteSupport = ({ participationType }: Props) => {
  const voteStyles = getParticipationTypeStyles()
  const voteStyle = voteStyles[participationType]

  return (
    <Flex align="center" justify="flex-end">
      <ParticipationLegend
        participationType={participationType}
        voteStyle={voteStyle}
      />
    </Flex>
  )
}

export default VoteSupport

import type { FC, ReactNode } from "react"
import type { ResponsiveValue } from "@chakra-ui/react"
import { Flex, Skeleton, Stack, Text, Tooltip } from "@chakra-ui/react"

type Props = {
  value: string | number | ReactNode
  label?: string
  isLoading?: boolean
  textAlign?: "left" | "right"
  size?: ResponsiveValue<string | number>
  labelSize?: ResponsiveValue<string | number>
  children?: ReactNode
  variant?: "standard" | "reverse" | "inline"
  alignItems?: "left" | "center" | "right" | "end" | "start"
  whiteSpace?: ResponsiveValue<any>
  color?: string
  tooltipText?: string
  containerHeight?: ResponsiveValue<string | number>
  dataQa?: string
}

const StatBox: FC<Props> = ({
  value,
  label,
  size = "lg",
  labelSize = "md",
  children,
  variant = "standard",
  textAlign = "left",
  alignItems = "left",
  isLoading = false,
  whiteSpace,
  color,
  tooltipText,
  containerHeight = "auto",
  dataQa,
}) => {
  if (isLoading) {
    return <Skeleton height={containerHeight} />
  }

  const content = (
    <Flex
      alignItems={alignItems}
      direction={
        variant === "inline"
          ? "row"
          : variant === "standard"
          ? "column"
          : "column-reverse"
      }
      height={containerHeight}
      textAlign={textAlign}
    >
      <Stack align="center" direction="row">
        <Text
          color={color}
          data-qa={dataQa ? dataQa : ""}
          fontSize={size}
          fontWeight="bold"
        >
          {value}
        </Text>
        {children}
      </Stack>
      {label ? (
        <Text
          color="gray.600"
          fontSize={labelSize}
          fontWeight="light"
          ml={variant === "inline" ? 1 : undefined}
          whiteSpace={whiteSpace ? whiteSpace : undefined}
        >
          {label}
        </Text>
      ) : null}
    </Flex>
  )

  return tooltipText ? (
    <Tooltip label={tooltipText}>{content}</Tooltip>
  ) : (
    content
  )
}

export default StatBox

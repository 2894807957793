import type { FC } from "react"

import MainLayoutBreadcrumb from "ui/layouts/main/MainLayoutBreadcrumb"
import type { Breadcrumb as BreadcrumbType } from "common/constants/breadcrumb"
import {
  useOrganization,
  FeatureFlag,
} from "organization/providers/OrganizationProvider"

type Props = {
  breadcrumb: BreadcrumbType
  isWhiteLabel?: boolean
  isHiddenOnMobile?: boolean
}

const DaoLayoutBreadcrumb: FC<Props> = ({
  breadcrumb,
  isWhiteLabel,
  isHiddenOnMobile = false,
}) => {
  const { isFeatureFlagOn } = useOrganization()
  const isLegacyLayoutEnabled = isFeatureFlagOn(FeatureFlag.LegacyLayout)

  const fixedBreadcrumb =
    !isLegacyLayoutEnabled && breadcrumb.length > 2
      ? breadcrumb.slice(2)
      : breadcrumb

  return (
    <MainLayoutBreadcrumb
      breadcrumb={fixedBreadcrumb}
      isHiddenOnMobile={isHiddenOnMobile}
      isWhiteLabel={isWhiteLabel}
    />
  )
}

export default DaoLayoutBreadcrumb

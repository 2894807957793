import type { FC, ReactNode } from "react"
import React from "react"
import { isAddress } from "@ethersproject/address"
import type { StackProps } from "@chakra-ui/react"
import { Stack, Text } from "@chakra-ui/react"

import CopyButton from "common/components/CopyButton"
import type { Account, Scalars } from "query/graphql"
import type { AvatarSize } from "common/components/UserAvatar"
import UserAvatar from "common/components/UserAvatar"
import AddressTooltip from "common/components/AddressTooltip"
import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import { shortString, subString } from "common/helpers/string"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { getDisplayName } from "user/helpers/user"

export type AUserIdentity = Pick<
  Account,
  "name" | "picture" | "address" | "twitter"
> & {
  // NOTE: `identities` is deprecated; but not all queries have been updated so
  // we will keep it around
  identities?: {
    twitter?: Scalars["String"] | null
  } | null
}

type UserIdentityProps = {
  index?: number
  account?: AUserIdentity
  spacing?: number
  hasFormStyle?: boolean
  hasCopyButton?: boolean
  isGnosisSafe?: boolean
  isTruncated?: boolean
  nameSize?: string
  nameWeight?: string
  avatarSize?: string
  isWhiteLabel?: boolean
  title?: string
  profileLink?: string
  displayProfileLink?: boolean
  slug?: string
  displayCard?: boolean
  nameMaxWidth?: string
  badge?: ReactNode
}

const UserIdentity: FC<UserIdentityProps & StackProps> = ({
  index,
  account,
  spacing = 3,
  hasFormStyle = false,
  hasCopyButton = false,
  avatarSize = "md",
  nameWeight = "bold",
  nameSize = "md",
  isWhiteLabel = false,
  title,
  profileLink,
  displayProfileLink = true,
  slug,
  displayCard = false,
  nameMaxWidth = "auto",
  badge,
  ...stackProps
}) => {
  const twitterUsername = account?.twitter
  const hasTwitterUsername = Boolean(twitterUsername)

  const getRoute = () => {
    if (!displayProfileLink) return undefined

    if (profileLink) return profileLink

    if (isWhiteLabel)
      return slug
        ? getWhiteLabelRoute(
            ROUTES.governance.delegate.profile(
              slug,
              account?.address as string,
            ),
          )
        : getWhiteLabelRoute(ROUTES.profile(account?.address as string))

    return slug
      ? ROUTES.governance.delegate.profile(slug, account?.address as string)
      : ROUTES.profile(account?.address as string)
  }
  const route = getRoute()

  const rank = index != null ? index + 1 : null
  const hasRank = index != null

  const defaultAvatarSize = hasFormStyle ? "sm" : avatarSize

  const displayName = account ? getDisplayName(account) : null

  return (
    <Stack
      isInline
      align="center"
      bgColor={hasFormStyle ? "gray.50" : undefined}
      border={hasFormStyle ? "gray.dark" : undefined}
      borderRadius={hasFormStyle ? 6 : undefined}
      p={hasFormStyle ? "2" : undefined}
      py="auto"
      spacing={spacing}
      w="full"
      {...stackProps}
    >
      {hasRank ? (
        <Text color="gray.600" minW={6} textStyle="body.bold.md">
          {rank}
        </Text>
      ) : null}
      <UserAvatar
        address={account?.address as string}
        isVerifiedByTwitter={hasTwitterUsername}
        size={defaultAvatarSize as AvatarSize}
        src={account?.picture}
      />
      <Stack spacing={0}>
        {route ? (
          <Link href={route}>
            <AddressTooltip
              aria-label="Complete address"
              label={account?.address}
            >
              {displayName ? (
                <Text
                  className="clamp-text-1"
                  color="gray.900"
                  fontSize={nameSize}
                  fontWeight={nameWeight}
                  lineHeight="20px"
                  maxW={nameMaxWidth}
                >
                  {isAddress(displayName)
                    ? shortString(displayName)
                    : subString(displayName, displayCard ? 30 : 200)}
                </Text>
              ) : null}
            </AddressTooltip>
          </Link>
        ) : (
          <AddressTooltip
            aria-label="Complete address"
            label={account?.address}
          >
            {displayName ? (
              <Text
                className="clamp-text-1"
                color="gray.900"
                fontSize={nameSize}
                fontWeight={nameWeight}
                lineHeight="20px"
              >
                {isAddress(displayName)
                  ? shortString(displayName)
                  : subString(displayName, displayCard ? 30 : 200)}
              </Text>
            ) : null}
          </AddressTooltip>
        )}
        {title ? (
          <Text
            color="gray.500"
            fontSize="sm"
            lineHeight="20px"
            maxW={{ base: "140px", lg: "none" }}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width="auto"
          >
            {title}
          </Text>
        ) : null}
      </Stack>
      {hasCopyButton ? (
        <CopyButton
          size={8}
          tooltipLabel="address"
          value={account?.address as string}
        />
      ) : null}

      {badge ? <>{badge}</> : null}
    </Stack>
  )
}

export default UserIdentity
